
import { useSpring, animated} from "@react-spring/web";


function Number ({ n }){
  const { number } = useSpring ({
    from: { number: 0 },
    number: n,
    delay: 200,
    config: {mass: 1, tension: 20, friction: 10},
  });
  return <animated.div>{number.to((n) => n.toFixed(0))}</animated.div>;
}
/**
 * 
 * @returns 
 */
function Testimonials() {

  return (
    // geral
    <div className="flex  justify-center  md:items-center">
      <div className="flex flex-col justify-center md:px-[11%] mt-8 min-w-[300px] max-w-[300px] md:justify-center   min-h-[600px] maz-h-[600px] md:min-w-full  md:max-w-full  md:grid md:grid-cols-12 ">
        {/* Esquerda */}
        <div className=" flex items-center md:flex-col md:col-span-6 pb-14   justify-center">
          <div className=" flex  gap-5 md:flex-col md:gap-7 xl:gap-10 lg:gap-3 lg:pl-10  xl:flex-row ">
            <div className=" flex flex-col items-center">
              <div className="text-[12px] md:text-[23px] text-center  font-semibold text-white">Novos clientes</div>
              <div className="text-[30px] md:text-[60px] font-bold text-Mega text-white flex">+<Number n={100}/></div>
            </div>
            <div className="flex flex-col  items-center">
              <div className="text-[12px] md:text-[23px] text-center font-semibold text-white">Atendimentos realizados</div>
              <div className="text-[30px] md:text-[60px] font-bold text-Mega text-white flex">+<Number n={2000}/></div>
            </div>
            <div className="flex flex-col  items-center"> 
              <div className="text-[11px] md:text-[23px] text-center font-semibold text-white">Velocidade de resposta</div>
              <div className="text-[30px] md:text-[60px] font-bold text-Mega text-white flex"><Number n={85}/>%</div>
            </div>
          </div>
        </div>
      
        {/* Direita */}
        <div className="md:mb-14 lg:mt-16 col-span-6 text-left font-Marri justify-center flex flex-col  items-center  ">
          <div className="mm:text-center lg:text-left">
            <div className="text-xl md:text-4xl font-InterBold text-white">Excelência em Cada Detalhe</div>
            <div className="text-Barro mt-2 font-InterRegular text-slate-200">
            Cultura e ambiente de trabalho incrivelmente positivos qual a empresa <br /> cultiva.{" "}
              É um lugar onde a inovação e a colaboração são não apenas <br /> encorajadas,  mas vividas diariamente.
            </div>
           
            <div className="flex items-center mm:justify-center lg:justify-start  mt-4">
             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Testimonials;

import React from 'react'
import Header from '../components/Header'
import Cover from '../components/Cover'
import Testimonials from '../components/Testimonials'
import Cards from '../components/Cards'
import Footer from "../components/Footer"

const Home = () => {
  return (
    <div>
      <div className='bg-hero-pattern mm:h-[1700px] lg:h-[1250px] gx:h-[1300px] xl:h-auto bg-no-repeat bg-cover bg-center'>
          <Header />
          <Cover/>
          <Testimonials/>
          
      </div>
      <Cards/>
      <Footer/>
    </div>
  )
}

export default Home
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Privacidade from "./pages/Privacidade";
import NotFound from "./pages/NotFound"

function App() {
  return (
    <div>
     <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/politica-de-privacidade" element={<Privacidade />} />
            <Route path="*" element={<NotFound/>} />
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React from 'react';
import { Link } from "react-router-dom"

const Footer = () => {

  return (
    <footer className="bg-[#0B0251]  text-white  py-6">
      <div className="container mx-auto text-center">
        <p className="text-sm">&copy; 2024 DALTON LIEBL - Todos os direitos reservados.</p>
        <nav className="mt-4">
          <ul className="flex justify-center items-center space-x-4">
            
            <li className=" cursor-pointer opacity-100 hover:opacity-80 transition duration-300">
              Contato
            </li>
            <li>
              <Link to={"/politica-de-privacidade"} className=" opacity-100 hover:opacity-80 transition duration-300">
                Política de Privacidade
              </Link>
            </li>
            
          </ul>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
